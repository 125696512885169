import {Center, Column, Row} from "../components/motion_mui";
import {
    Box,
    Button,
    Grid, IconButton,
    Paper,
    Typography,
    useTheme
} from "@material-ui/core";
import {useStyles} from "../hooks/styles";
// npm install --save-dev @iconify/react @iconify-icons/mdi
import {Icon} from '@iconify/react';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import React, {useEffect, useRef, useState} from "react";
import WidgetsRoundedIcon from '@material-ui/icons/WidgetsRounded';
// npm install --save-dev @iconify/react @iconify-icons/ant-design
import chromeFilled from '@iconify-icons/ant-design/chrome-filled';
// import linkedinFilled from '@iconify-icons/ant-design/linkedin-filled';
// npm install --save-dev @iconify/react @iconify-icons/mdi
// import { Icon, InlineIcon } from '@iconify/react';
import linkedinIcon from '@iconify-icons/mdi/linkedin';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {useInView} from "react-intersection-observer";
import {motion} from "framer-motion";
// import {useAnimation} from "framer-motion";
// import useDimensions from "react-cool-dimensions";
import useWindowDimensions from "../hooks/window";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import {isMobileOnly as deviceIsMobile} from 'react-device-detect';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const youtubeVideoLink = "https://www.youtube.com/embed/_aw6zw2vIZA"
const downloadLink = "https://github.com/qanda-link/qanda-software/releases/tag/v0.0.7"
const imgHeight = "100vh"
const imgHeightBottom = "200px"

function FadeInWhenVisible(props: React.PropsWithChildren<{}>) {
    return (
        <>
            {props.children}
        </>
    )

    // const controls = useAnimation();
    // const [ref, inView] = useInView();
    //
    // useEffect(() => {
    //     if (inView) {
    //         controls.start("visible");
    //     }
    // }, [controls, inView]);
    //
    // return (
    //     <ColumnMotion
    //         style={{
    //             flexGrow: 1,
    //             width: "100%"
    //         }}
    //         mainAxisAlignment={"start"}
    //         crossAxisAlignment={"center"}
    //         ref={ref}
    //         animate={controls}
    //         initial="hidden"
    //         transition={{duration: 0.3}}
    //         variants={{
    //             visible: {opacity: 1, scale: 1},
    //             hidden: {opacity: 0, scale: 0.8}
    //         }}
    //     >
    //         {props.children}
    //     </ColumnMotion>
    // );
}


export function QAndAText() {
    const theme = useTheme()
    const primaryTextColor = theme.palette.text.primary
    const primaryMainColor = theme.palette.primary.main
    return (
        <>
            <span style={{color: primaryTextColor}}>
                qand
            </span>
            <span style={{color: primaryMainColor}}>
                a
            </span>
            <span style={{color: primaryTextColor}}>
                .link
            </span>
        </>
    )
}

function SectionTitle(props: { title: string, maxWidth: number }) {
    return (<Column style={{
            textAlign: "center",
            padding: "0 64px",
            marginTop: "64px",
            maxWidth: props.maxWidth + "px"
        }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
            <Typography variant={"h2"}>
                {props.title}
            </Typography>
        </Column>
    )
}


function SectionTitleWithSubtitle(props: { title: string, subtitle: string, maxWidth: number, marginSide: string }) {
    return (
        <Column style={{
            textAlign: "center",
            maxWidth: props.maxWidth - 2 * 96 + "px",
            padding: `0px ${props.marginSide}`,
            marginTop: "64px",
        }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
            <Typography variant={"h2"}>
                {props.title}
            </Typography>
            <Box height={"8px"}/>
            <Typography variant={"subtitle1"}>
                {props.subtitle}
            </Typography>
        </Column>
    )
}

function HelpCard(props: React.PropsWithChildren<{ title: string, body: string }>) {
    return (
        <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            {/*<Icon style={{*/}
            {/*    width: "64px",*/}
            {/*    height: "64px"*/}
            {/*}} icon={headSnowflakeOutline} />*/}

            <Column style={{
                // marginLeft: "32px"
            }} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                {
                    props.children
                }
                <Box height={"16px"}/>
                <Typography variant={"h3"}>
                    {
                        props.title
                    }
                </Typography>
                <Box height={"8px"}/>
                <Typography variant={"subtitle1"}>
                    {
                        props.body
                    }
                </Typography>
            </Column>
        </Row>
    )
}

function GettingStartedCard(props: React.PropsWithChildren<{ title: string, body: string }>) {
    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
            <Center style={{
                flexGrow: 1,
            }}>
                {
                    props.children
                }
            </Center>
            <Box height={"32px"}/>
            <Typography variant={"h3"}>
                {
                    props.title
                }
            </Typography>
            <Box height={"8px"}/>
            <Typography variant={"subtitle1"}>
                {
                    props.body
                }
            </Typography>
        </Column>
    )
}

function ProfileCard(props: {
    name: string,
    role: string,
    email: string,
    linkedin: string,
    education: string,
}) {
    // const classes = useStyles()
    // const theme = useTheme()
    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            <Typography variant={"h3"}>{props.name}, {props.role}</Typography>
            {/*<Typography variant={"h3"}>{props.role}</Typography>*/}
            {/*<Typography variant={"subtitle1"}>{props.email}</Typography>*/}
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                <IconButton color={"primary"} edge={"start"} onClick={() => {
                    window.open("mailto:" + props.email, '_blank')
                }}>
                    <MailRoundedIcon/>
                </IconButton>
                <IconButton color={"primary"} onClick={() => {
                    window.open(props.linkedin, '_blank')
                }}>
                    <Icon icon={linkedinIcon}/>
                </IconButton>
            </Row>
            <Typography variant={"subtitle1"}>{props.education}</Typography>
        </Column>
    )
}


export function Home() {

    // const deviceIsMobile = false
    const classes = useStyles()
    const theme = useTheme()
    // const primaryTextColor = theme.palette.text.primary
    // const primaryMainColor = theme.palette.primary.main
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSMUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMDUp = useMediaQuery(theme.breakpoints.up('md'));
    const marginSide = matchesSMUp ? "64px" : "32px"
    const maxContainerWidth = 998
    const windowDims = useWindowDimensions() as { width: number }

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const iframeNode = iframeRef.current

    const [guideHeight, setGuideHeight] = useState(0)
    const guidePadding = matchesSMUp ? 128 : 64

    useEffect(() => {
        if (iframeNode) {
            const win = iframeNode.contentWindow
            if (win) {
                win.postMessage({
                    message: "setWindowWidth",
                    data: windowDims.width
                }, "*")
            }
        }
    }, [windowDims.width, iframeNode])

    useEffect(() => {
        const messageHandler = (event: MessageEvent) => {
            if (event.data.message === "guideHeight") {
                setGuideHeight(event.data.data)
            }
        }
        window.addEventListener("message", messageHandler, false)
        return () => {
            window.removeEventListener("message", messageHandler);
        }
    })

    const [ref, inView] = useInView();

    return (

        <Column style={{
            maxWidth: "100vw",
            overflow: "hidden",
        }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
            {
                !inView ?
                    <motion.div animate={{
                        y: [0, 10, 0],
                    }} transition={{
                        repeat: Infinity,
                        duration: 2,
                        delay: 1
                    }} style={{
                        position: "absolute",
                        color: "white",
                        width: "64px",
                        height: "64px",
                        bottom: "8px",
                        left: "calc(50vw - 32px)"
                    }}>
                        <KeyboardArrowDownRoundedIcon style={{
                            color: deviceIsMobile ? theme.palette.primary.main : "white",
                            width: "64px",
                            height: "64px",
                        }}/>
                    </motion.div> :
                    <KeyboardArrowDownRoundedIcon style={{
                        position: "absolute",
                        color: deviceIsMobile ? theme.palette.primary.main : "white",
                        width: "64px",
                        height: "64px",
                        bottom: "8px",
                        left: "calc(50vw - 32px)"
                    }}/>
            }
            {
                !deviceIsMobile &&

                <Center style={{
                    position: "absolute",
                    height: imgHeight,
                    width: "100%",
                    overflow: "hidden",
                    zIndex: -1
                }}>
                    <img style={{
                        minHeight: imgHeight, // 819, 524.16, 819/524.16=1.5625
                        // maxHeight: "500px",
                        width: `max(100%, 1.5625*${imgHeight})`
                    }} src={"/assets/images/vecteezy_low_res_marble.jpg"} alt={"marble blue ocean"}/>
                </Center>
            }
            {
                deviceIsMobile ?
                    <Column style={{
                        // maxWidth: "1400px",

                        margin: `64px ${marginSide} 0px`,
                        // backgroundColor: "white",
                        // margin: "64px",

                        // marginTop: "64px",
                        // marginLeft: "64px",
                        // marginRight: "64px",
                        textAlign: "center",
                        height: "100vh",
                        width: "calc(100% - 64px)"
                        // height: `calc(${imgHeight}px - 280px)`
                    }} mainAxisAlignment={deviceIsMobile ? "start" : "center"}
                            crossAxisAlignment={"center"}>
                        {/*<Box height={"32px"}/>*/}

                        <img height={"64px"} src={"/assets/images/qanda_logo_simple.svg"} alt={"logo"}/>
                        <Box height={"16px"}/>
                        <Typography variant={deviceIsMobile ? "h2" : "h1"}>
                            Bringing minds together through Q&A
                        </Typography>
                        <Box height={"8px"}/>
                        <Typography variant={"subtitle1"}>
                            Qanda links content on web pages and pdf documents to questions and answers
                        </Typography>
                        {/*<a href={"https://github.com/qanda-link/qanda-software/releases/tag/v0.0.6"}>Download</a>*/}
                        <Box height={deviceIsMobile ? "16px" : "64px"}/>
                        {/*<Typography style={{*/}
                        {/*    backgroundColor: "#F9FCFF",*/}
                        {/*    padding: "8px 16px",*/}
                        {/*    borderRadius: "50px",*/}
                        {/*}} variant={"h5"}>*/}
                        {/*    Launch in September*/}
                        {/*</Typography>*/}
                        <Button style={{
                            borderRadius: "50px",
                            backgroundColor: "#F9FCFF",
                            textTransform: "none",
                            padding: "0px"
                        }} onClick={() => {
                            window.open(downloadLink)
                        }}>
                            <Typography style={{
                                padding: "8px 16px",
                                borderRadius: "50px",
                            }} variant={"h5"}>
                                Download <OpenInNewOutlinedIcon className={classes.inlineIcon}/>
                            </Typography>
                        </Button>
                        {/*<Box height={"32px"}/>*/}
                    </Column> :
                    <Center style={{
                        height: deviceIsMobile ? "100vh" : imgHeight,
                        width: "100%"
                    }}>
                        <Paper elevation={0} style={{
                            // padding: "64px",
                            ...(!deviceIsMobile) && {
                                margin: `64px ${marginSide}`,
                            },
                            borderRadius: "0px"
                        }}>
                            <Column style={{
                                // maxWidth: "1400px",

                                padding: `64px ${marginSide}`,
                                // backgroundColor: "white",
                                // margin: "64px",

                                // marginTop: "64px",
                                // marginLeft: "64px",
                                // marginRight: "64px",
                                textAlign: "center",
                                // height: `calc(${imgHeight}px - 280px)`
                            }} mainAxisAlignment={deviceIsMobile ? "start" : "center"}
                                    crossAxisAlignment={"center"}>
                                {/*<Box height={"32px"}/>*/}

                                <img height={"64px"} src={"/assets/images/qanda_logo_simple.svg"} alt={"logo"}/>
                                <Box height={"16px"}/>
                                <Typography variant={deviceIsMobile ? "h2" : "h1"}>
                                    Bringing minds together through Q&A
                                </Typography>
                                <Box height={"8px"}/>
                                <Typography variant={"subtitle1"}>
                                    Qanda links content on web pages and pdf documents to questions and answers
                                </Typography>
                                {/*<a href={"https://github.com/qanda-link/qanda-software/releases/tag/v0.0.6"}>Download</a>*/}
                                <Box height={deviceIsMobile ? "16px" : "64px"}/>
                                {/*<Typography style={{*/}
                                {/*    backgroundColor: "#F9FCFF",*/}
                                {/*    padding: "8px 16px",*/}
                                {/*    borderRadius: "50px",*/}
                                {/*}} variant={"h5"}>*/}
                                {/*    Launch in September*/}
                                {/*</Typography>*/}
                                <Button style={{
                                    borderRadius: "50px",
                                    backgroundColor: "#F9FCFF",
                                    textTransform: "none",
                                    padding: "0px"
                                }} onClick={() => {
                                    window.open(downloadLink)
                                }}>
                                    <Typography style={{
                                        padding: "8px 16px",
                                        borderRadius: "50px",
                                    }} variant={"h5"}>
                                        Download <OpenInNewOutlinedIcon className={classes.inlineIcon}/>
                                    </Typography>
                                </Button>
                                {/*<Box height={"32px"}/>*/}
                            </Column>
                        </Paper>
                    </Center>

            }

            {/*<FadeInWhenVisible>*/}
                <Column style={{
                    textAlign: "center",
                    maxWidth: maxContainerWidth - 2 * 96 + "px",
                    padding: `0px ${marginSide}`,
                    marginTop: deviceIsMobile ? "0px" : "64px",
                }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                    <Typography variant={"h2"}>
                        Product tour video
                    </Typography>
                </Column>
                {/*<SectionTitleWithSubtitle marginSide={marginSide}*/}
                {/*                          maxWidth={maxContainerWidth}*/}
                {/*                          title={"Try it out!"}*/}
                {/*                          subtitle={"We built an interactive demo that allows you to explore the capabilities of qanda without having to register"}/>*/}
            {/*</FadeInWhenVisible>*/}

            <div ref={ref}>
                <Box height={"64px"}/>
            </div>

            {/*<FadeInWhenVisible>*/}
                <iframe style={{
                    width: "100vw",
                    height: "calc(0.5625 * 100vw)"
                }}
                        src={youtubeVideoLink}
                        title={"YouTube video player"}
                        frameBorder={"0"}
                        allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                        allowFullScreen></iframe>
            {/*</FadeInWhenVisible>*/}

            {/*<FadeInWhenVisible>*/}
            {/*    <SectionTitleWithSubtitle marginSide={marginSide}*/}
            {/*                              maxWidth={maxContainerWidth} title={"Try it yourself!"}*/}
            {/*                              subtitle={"We built an interactive demo that allows you to explore the capabilities of qanda locally on your computer and without having to register"}/>*/}
            {/*</FadeInWhenVisible>*/}
            {/*/!*<FadeInWhenVisible>*!/*/}
            {/*/!*    <Column style={{*!/*/}
            {/*/!*        textAlign: "center",*!/*/}
            {/*/!*        maxWidth: maxContainerWidth - 2 * 96 + "px",*!/*/}
            {/*/!*        padding: `0px ${marginSide}`,*!/*/}
            {/*/!*        marginTop: deviceIsMobile ? "0px" : "64px",*!/*/}
            {/*/!*    }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>*!/*/}
            {/*/!*        <Typography variant={"h2"}>*!/*/}
            {/*/!*            Try it out!*!/*/}
            {/*/!*        </Typography>*!/*/}
            {/*/!*        <Box height={"8px"}/>*!/*/}
            {/*/!*        <Typography variant={"subtitle1"}>*!/*/}
            {/*/!*            We built an interactive demo that allows you to explore the capabilities of qanda without having*!/*/}
            {/*/!*            to*!/*/}
            {/*/!*            register*!/*/}
            {/*/!*        </Typography>*!/*/}
            {/*/!*    </Column>*!/*/}
            {/*/!*    /!*<SectionTitleWithSubtitle marginSide={marginSide}*!/*!/*/}
            {/*/!*    /!*                          maxWidth={maxContainerWidth}*!/*!/*/}
            {/*/!*    /!*                          title={"Try it out!"}*!/*!/*/}
            {/*/!*    /!*                          subtitle={"We built an interactive demo that allows you to explore the capabilities of qanda without having to register"}/>*!/*!/*/}
            {/*/!*</FadeInWhenVisible>*!/*/}
            {/*<Box height={"64px"}/>*/}
            {/*<FadeInWhenVisible>*/}
            {/*    <Grid container spacing={0} style={{*/}
            {/*        width: "100vw",*/}
            {/*        maxWidth: "1868px"*/}
            {/*        // overflow: "hidden"*/}
            {/*    }}>*/}
            {/*        <Grid item xs={12} md={5} style={{*/}
            {/*            backgroundColor: "#E5E5E5"*/}
            {/*        }}>*/}
            {/*            <Column style={{*/}
            {/*                padding: matchesSMUp ? "64px" : "32px"*/}
            {/*            }} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>*/}
            {/*                <Typography variant={"h3"}>*/}
            {/*                    Explore*/}
            {/*                </Typography>*/}
            {/*                <Box height={"32px"}/>*/}
            {/*                <Typography variant={"body1"}>*/}
            {/*                    You can try qanda in the demo window {matchesMDUp ? "on the right" : "below"}. All*/}
            {/*                    interactions*/}
            {/*                    happen locally (a*/}
            {/*                    page*/}
            {/*                    refresh will clear all data).*/}
            {/*                </Typography>*/}

            {/*                <Box height={"32px"}/>*/}
            {/*                <Typography variant={"h4"}>*/}
            {/*                    Post your first question*/}
            {/*                </Typography>*/}
            {/*                <Box height={"8px"}/>*/}
            {/*                <Typography variant={"body1"}>*/}
            {/*                    <ol style={{margin: "0px"}}>*/}
            {/*                        <li>*/}
            {/*                            Select a text passage and click „add question“ in the popup window*/}
            {/*                        </li>*/}
            {/*                        <li>*/}
            {/*                            Specify a title and body and click „post question“*/}
            {/*                        </li>*/}
            {/*                    </ol>*/}
            {/*                </Typography>*/}

            {/*                <Box height={"32px"}/>*/}
            {/*                <Typography variant={"h4"}>*/}
            {/*                    Accept an answer*/}
            {/*                </Typography>*/}
            {/*                <Box height={"8px"}/>*/}
            {/*                <Typography variant={"body1"}>*/}
            {/*                    <ol style={{margin: "0px"}}>*/}
            {/*                        <li>*/}
            {/*                            Your question will receive an automated answer from us*/}
            {/*                        </li>*/}
            {/*                        <li>*/}
            {/*                            Click on <CheckCircleOutlinedIcon className={classes.inlineIcon}/> to accept the*/}
            {/*                            answer*/}
            {/*                        </li>*/}
            {/*                    </ol>*/}
            {/*                </Typography>*/}

            {/*                <Box height={"32px"}/>*/}
            {/*                <Typography variant={"h4"}>*/}
            {/*                    Try qanda for PDF*/}
            {/*                </Typography>*/}
            {/*                <Box height={"8px"}/>*/}
            {/*                <Typography variant={"body1"}>*/}
            {/*                    <ol style={{margin: "0px"}}>*/}
            {/*                        <li>*/}
            {/*                            Open the qanda PDF viewer by clicking on the <OpenInNewOutlinedIcon*/}
            {/*                            className={classes.inlineIcon}/> icon next to the PDF link*/}
            {/*                        </li>*/}
            {/*                        <li>*/}
            {/*                            Click on <AddCircleOutlineOutlinedIcon className={classes.inlineIcon}/> to add a*/}
            {/*                            new*/}
            {/*                            question*/}
            {/*                        </li>*/}
            {/*                    </ol>*/}
            {/*                </Typography>*/}
            {/*            </Column>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} md={7} style={{*/}
            {/*            // maxHeight: "100vh",*/}
            {/*            ...(!matchesMDUp) && {*/}
            {/*                height: (guideHeight + guidePadding) + "px",*/}
            {/*            }*/}
            {/*        }}>*/}
            {/*            <iframe ref={iframeRef} src={"/guide"} style={{*/}
            {/*                flexGrow: 1,*/}
            {/*                border: "none",*/}
            {/*                width: "100%",*/}
            {/*                height: "100%",*/}
            {/*            }}/>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</FadeInWhenVisible>*/}

            {/*<Box height={"64px"}/>*/}

            {/*<FadeInWhenVisible>*/}

            {/*    <SectionTitleWithSubtitle marginSide={marginSide}*/}
            {/*                              maxWidth={maxContainerWidth} title={"The dashboard"}*/}
            {/*                              subtitle={"The dashboard allows you to manage your notifications, account and URLs"}/>*/}
            {/*    <Box height={"32px"}/>*/}
            {/*    <Button style={{*/}
            {/*        borderRadius: "50px",*/}
            {/*        backgroundColor: "#F9FCFF",*/}
            {/*        textTransform: "none",*/}
            {/*        padding: "0px"*/}
            {/*    }} onClick={() => {*/}
            {/*        window.open("https://app.qanda.link/demo/dashboard")*/}
            {/*    }}>*/}
            {/*        <Typography style={{*/}

            {/*            padding: "8px 16px",*/}
            {/*            borderRadius: "50px",*/}
            {/*        }} variant={"h5"}>*/}
            {/*            Try the demo <OpenInNewOutlinedIcon className={classes.inlineIcon}/>*/}
            {/*        </Typography>*/}
            {/*    </Button>*/}
            {/*</FadeInWhenVisible>*/}
            {/*<Box height={"64px"}/>*/}

            <FadeInWhenVisible>
                <SectionTitle maxWidth={maxContainerWidth} title={"How qanda can help you"}/>
            </FadeInWhenVisible>
            <Box height={"64px"}/>
            <FadeInWhenVisible>
                <Grid container spacing={matchesSMUp ? 8 : 4} style={{
                    // minWidth: "calc(100% - 64px)",
                    padding: matchesSMUp ? "0 64px" : "0 32px",
                    maxWidth: matchesLgUp ? 2 * maxContainerWidth : maxContainerWidth
                }}>
                    <Grid item xs={12} md={6} lg={3}>
                        <HelpCard title={"Save Time"}
                                  body={"Avoid repetitive questions. See your question already answered by the qanda community."}>
                            <AccessTimeOutlinedIcon style={{
                                width: "48px",
                                height: "48px",
                                transform: "translateX(-8.3%)"
                            }}/>
                        </HelpCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <HelpCard title={"Expert Answers"}
                                  body={"Qanda promotes the wisdom of the crowd. Answers get better over time through our proposal mechanism, ensuring a high level of quality content."}>
                            <PeopleOutlinedIcon style={{
                                width: "48px",
                                height: "48px",
                                transform: "translateX(-8.3%)"
                            }}/>
                        </HelpCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <HelpCard title={"Deeper Learning"}
                                  body={"Question answering & question generation are among the most effective ways to improve reading comprehension according to the US National Reading Panel. "}>
                            <SchoolOutlinedIcon style={{
                                width: "48px",
                                height: "48px",
                                transform: "translateX(-8.3%)"
                            }}/>
                        </HelpCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <HelpCard title={"Valuable Feedback"}
                                  body={"Questions reveal parts in your web page or PDF document that are unclear to your readership."}>
                            <FeedbackOutlinedIcon style={{
                                width: "48px",
                                height: "48px",
                                transform: "translateX(-8.3%)"
                            }}/>
                        </HelpCard>
                    </Grid>
                </Grid>
            </FadeInWhenVisible>

            <Box height={"64px"}/>
            <FadeInWhenVisible>

                <SectionTitleWithSubtitle marginSide={marginSide}
                                          maxWidth={maxContainerWidth} title={"Getting started"}
                                          subtitle={"Qanda will be available as a Chrome extension and as a web widget"}/>
            </FadeInWhenVisible>
            <Box height={"64px"}/>
            <FadeInWhenVisible>


                <Grid container spacing={matchesSMUp ? 8 : 4} style={{
                    padding: matchesSMUp ? "0 64px" : "0 32px",
                    maxWidth: maxContainerWidth
                }}>
                    <Grid item xs={12} md={6}>
                        <GettingStartedCard title={"Chrome Extension"}
                                            body={"Chrome extensions extend the functionality of Chrome. Our Chrome extension will allow you to use qanda on every web page!"}>
                            <Icon style={{
                                height: "128px",
                                width: "128px",
                                color: theme.palette.primary.main
                            }} icon={chromeFilled}/>
                        </GettingStartedCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <GettingStartedCard title={"Web Widget"}
                                            body={"The web widget is for web hosts to unlock the features of qanda for all site visitors, even those who do not have the Chrome extension installed."}>
                            <WidgetsRoundedIcon style={{
                                height: "128px",
                                width: "128px",
                                color: theme.palette.primary.main
                            }}/>
                        </GettingStartedCard>
                    </Grid>
                </Grid>
            </FadeInWhenVisible>

            <Box height={"64px"}/>
            <FadeInWhenVisible>
                <SectionTitleWithSubtitle marginSide={marginSide}
                                          maxWidth={maxContainerWidth} title={"About us"} subtitle={
                    "We started qanda because we wanted to make reading lecture notes, Wikipedia articles, research papers, and all other types of educational articles more effective and fun. We believe qanda will increase the quality of research & education and help millions of people collaborate more productively."
                }/>

            </FadeInWhenVisible>
            <Box height={"64px"}/>

            <FadeInWhenVisible>

                <Grid container spacing={matchesSMUp ? 8 : 4} style={{
                    padding: matchesSMUp ? "0 64px" : "0 32px",
                    maxWidth: maxContainerWidth
                }}>
                    <Grid item xs={12} md={6}>
                        <ProfileCard
                            name={"Krispin Wandel"}
                            role={"CEO"}
                            email={"krispin.wandel@qanda.link"}
                            linkedin={"https://www.linkedin.com/in/krispin-wandel/"}
                            education={"Master at ETH Zurich in Computational Science and Engineering"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProfileCard
                            name={"Thiemo Wandel"}
                            role={"CTO"}
                            email={"thiemo.wandel@qanda.link"}
                            linkedin={"https://www.linkedin.com/in/thiemo-wandel/"}
                            education={"Bachelor at ETH Zurich in Electrical Engineering"}
                        />
                    </Grid>
                </Grid>
            </FadeInWhenVisible>

            <Box height={"64px"}/>

            <FadeInWhenVisible>

                <SectionTitleWithSubtitle marginSide={marginSide}
                                          maxWidth={maxContainerWidth} title={"Join the pilot program!"} subtitle={
                    "Get early access to the Chrome extension & web widget and be part of the development of qanda"
                }/>
            </FadeInWhenVisible>
            <Box height={"64px"}/>
            <FadeInWhenVisible>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfrgLVZrftWd1FtuCgY3hbX6ev3i_TB4sPrQ0xPsnTy2lDhDQ/viewform?embedded=true"
                    width="100%" height="1182" frameBorder="0">Wird geladen…
                </iframe>
            </FadeInWhenVisible>
            {/*<iframe*/
            }
            {/*    src="https://docs.google.com/forms/d/e/1FAIpQLScbLM5Nyl7PdVxZ1mbZ_xTi8vGrXqqvjoijrJnH5e6EVgycOQ/viewform?embedded=true"*/
            }
            {/*    width="100%" height="500px" frameBorder="0">Wird geladen…*/
            }
            {/*</iframe>*/
            }
            <Box height={"64px"}/>

            <div style={{
                position: "relative",
                height: imgHeightBottom,
                width: "100%"
            }}>

                {
                    !deviceIsMobile &&
                    <Center style={{
                        position: "absolute",
                        height: imgHeightBottom,
                        width: "100%",
                        overflow: "hidden",
                        zIndex: -1,
                        bottom: 0,
                    }}>
                        <img style={{
                            minHeight: imgHeightBottom, // 819, 524.16, 819/524.16=1.5625
                            // maxHeight: "500px",
                            width: `max(100%, 1.5625*${imgHeightBottom})`
                        }} src={"/assets/images/vecteezy_low_res_marble.jpg"} alt={"marble blue ocean"}/>
                    </Center>
                }
                <Center style={{
                    height: imgHeightBottom,
                    width: "100%",
                }}>
                    <Column style={{
                        height: imgHeightBottom,
                        backgroundColor: "white",
                        // maxWidth: "1400px",
                        padding: "0 64px",
                        // backgroundColor: "white",
                        // margin: "64px",

                        // marginTop: "64px",
                        // marginLeft: "64px",
                        // marginRight: "64px",
                        textAlign: "center",
                        // height: `calc(${imgHeight}px - 280px)`
                    }} mainAxisAlignment={"center"}
                            crossAxisAlignment={"center"}>
                        {/*<Box height={"32px"}/>*/}

                        <img height={"64px"} src={"/assets/images/qanda_logo_simple.svg"} alt={"logo"}/>
                        <Box height={"16px"}/>
                        <Typography style={{
                            fontWeight: 300,
                            fontSize: "12pt",
                        }}>
                            qanda.link © 2021
                        </Typography>
                        <Typography style={{
                            fontWeight: 300,
                            fontSize: "6pt",
                        }}>
                            <a className={classes.smallLink} href="https://www.vecteezy.com/free-vector/inkscape">Inkscape
                                Vectors by Vecteezy</a>
                        </Typography>

                        {/*<Box height={"32px"}/>*/}
                    </Column>
                </Center>
            </div>

            {/*<Center style={{textAlign: "center", width: "100vw"}}>*/
            }
            {/*    <Column style={{maxWidth: "1400px", padding: "0px"}} mainAxisAlignment={"center"}*/
            }
            {/*            crossAxisAlignment={"center"}>*/
            }
            {/*        <Typography className={classes.accentFont} variant={"h2"}>*/
            }
            {/*            <i>Let's do a brief Q&A to get you started:</i>*/
            }
            {/*        </Typography>*/
            }
            {/*    </Column>*/
            }
            {/*</Center>*/
            }
            {/*<Column style={{maxWidth: "1400px", padding: "32px"}} mainAxisAlignment={"center"}*/
            }
            {/*        crossAxisAlignment={"stretch"}>*/
            }
            {/*    <Box height={"48px"}/>*/
            }
            {/*    <Typography variant={"h2"}>*/
            }
            {/*        What is <QAndAText/>?*/
            }
            {/*    </Typography>*/
            }
            {/*    <Box height={"24px"}/>*/
            }
            {/*    <Column className={classes.topicCard} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>*/
            }
            {/*        <Typography variant={"h2"} className={classes.accentFont}>*/
            }
            {/*            <b>A browser extension</b>*/
            }
            {/*        </Typography>*/
            }
            {/*    </Column>*/
            }
            {/*    <Box height={"48px"}/>*/
            }
            {/*    <Typography className={classes.sectionTitle} variant={"h1"}>*/
            }
            {/*        <b>Why should I care?</b>*/
            }
            {/*    </Typography>*/
            }
            {/*    <Box height={"24px"}/>*/
            }
            {/*    <Grid container spacing={4}>*/
            }
            {/*        <Grid item xs={12}>*/
            }
            {/*            <Column style={{minHeight: "300px"}} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}*/
            }
            {/*                    className={classes.topicCard}>*/
            }
            {/*                <Typography variant={"h2"} className={classes.accentFont}>*/
            }
            {/*                    <b>A browser extension</b>*/
            }
            {/*                </Typography>*/
            }
            {/*            </Column>*/
            }
            {/*        </Grid>*/
            }
            {/*        <Grid item xs={12} md={6}>*/
            }
            {/*            <Column style={{minHeight: "300px"}} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}*/
            }
            {/*                    className={classes.topicCard}>*/
            }
            {/*                <Typography variant={"h2"} className={classes.accentFont}>*/
            }
            {/*                    <b>A browser extension</b>*/
            }
            {/*                </Typography>*/
            }
            {/*            </Column>*/
            }
            {/*        </Grid>*/
            }

            {/*        <Grid item xs={12} md={6}>*/
            }
            {/*            <Column style={{minHeight: "300px"}} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}*/
            }
            {/*                    className={classes.topicCard}>*/
            }
            {/*                <Typography variant={"h2"} className={classes.accentFont}>*/
            }
            {/*                    <b>A browser extension</b>*/
            }
            {/*                </Typography>*/
            }
            {/*            </Column>*/
            }
            {/*        </Grid>*/
            }

            {/*        <Grid item xs={12}>*/
            }
            {/*            <Column style={{minHeight: "300px"}} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}*/
            }
            {/*                    className={classes.topicCard}>*/
            }
            {/*                <Typography variant={"h2"} className={classes.accentFont}>*/
            }
            {/*                    <b>A browser extension</b>*/
            }
            {/*                </Typography>*/
            }
            {/*            </Column>*/
            }
            {/*        </Grid>*/
            }
            {/*    </Grid>*/
            }
            {/*</Column>*/
            }
        </Column>
    )
}