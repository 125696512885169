import {makeStyles} from "@material-ui/core";
// import {deepOrange} from "@material-ui/core/colors";


export const useStyles = makeStyles((theme) => ({
    accentFont: {
        color: theme.palette.primary.main
        // color: theme.palette.common.white[500]
    },
    topicCard: {
        boxShadow: "0px 20px 50px 0px rgba(0, 99, 220, 0.08)",
        padding: "24px",
        borderRadius: "48px",
        // backgroundColor: "white"
        // background: "rgb(0,99,220) linear-gradient(173deg, rgba(0,99,220,1) 0%, rgba(1,41,111,1) 74%, rgba(2,0,36,1) 100%, rgba(251,253,255,1) 100%)"
    },
    sectionTitle: {
        color: "black"
        // color: "#F7F9F9"
    },
    inlineIcon: {
        fontSize: "inherit !important",
        verticalAlign: "baseline",
        // NOTE verticalAlign: "top" is better than transform: "translateY(8.3%)"
        // icons viewport is from 0 to 24
        // material icons go from 2 to 20
        // to align with text we translate it down by 2/24% = 8.33%
        // https://material.io/design/iconography/system-icons.html
        transform: "translateY(9%)",
        position: "relative"
    },
    smallLink: {
        textDecoration: "none !important",
        color: "inherit !important"
    }

}));